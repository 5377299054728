import React, { Component } from "react";
import DropdownTreeSelect, {
  TreeData,
  TreeNodeProps,
} from "react-dropdown-tree-select";
import isEqual from "lodash/isEqual";

class DropdownContainer extends Component<
  DropdownContainerProps,
  DropdownContainerState
> {
  constructor(props: DropdownContainerProps) {
    super(props);
    this.state = { data: props.data };
  }
  // TODO update
  UNSAFE_componentWillReceiveProps(nextProps: DropdownContainerProps) {
    if (!isEqual(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data });
    }
  }

  shouldComponentUpdate(nextProps: DropdownContainerProps) {
    return !isEqual(nextProps.data, this.state.data);
  }

  render() {
    /* jshint unused:false */
    const { data, placeholderText, noMatchesText, ...rest } = this.props;
    return (
      <DropdownTreeSelect
        data={this.state.data}
        texts={{
          placeholder: placeholderText,
          noMatches: noMatchesText,
        }}
        {...rest}
      />
    );
  }
}

export type DropdownData = TreeNodeProps;
interface DropdownContainerProps {
  data: DropdownData[];
  onChange: (currentNode: DropdownData, selectedNodes: DropdownData[]) => void;
  placeholderText: string;
  noMatchesText: string;
  className: string;
}
interface DropdownContainerState {
  data: DropdownData[];
}
export default DropdownContainer;
