/* global Image */
import Background from "./Background.js";

export default class YeastBackground {
  constructor(element) {
    var img = new Image();
    img.src =
      " data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAHUlEQVQI12NgYGD4D8UMDAwMDEwMaIARif0fqwoAklwDA9sV5+kAAAAASUVORK5CYII=";

    img.onload = function () {
      /* eslint no-new: 0 */
      new Background(element, img);
    };
  }
}
