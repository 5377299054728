import React from "react";
import { graphql, PageProps } from "gatsby";

import "../sass/projects.scss";

interface ProjectData {
  projects: {
    nodes: {
      title: string;
      content: string;
      id: string;
      meta: {
        date: Date;
      };
    }[];
  };
}
const Projects: React.FC<PageProps<ProjectData>> = ({ data }) => {
  const projects = data.projects.nodes;
  return (
    <div className="box-wrapper">
      <h2 className="box-title">Projets</h2>
      <div className="box projects">
        {projects.map((project) => (
          <div key={project.id} className="project">
            <h3 className="header">{project.title}</h3>
            <span className="date">{project.meta.date}</span>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: project.content }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};
export const query = graphql`
  query Projects {
    projects: allWpProject(sort: { fields: projects___date, order: DESC }) {
      nodes {
        title
        id
        content
        meta: projects {
          date(formatString: "D/M/Y")
        }
      }
    }
  }
`;
export default Projects;
