import { defineMessages } from "react-intl";

const messages = defineMessages({
  map_title: {
    id: "clients_map_title",
    defaultMessage: "Nos clients",
    description: "Map title",
  },
  clients_title: {
    id: "clients_title",
    defaultMessage: "Nos {number} clients",
    description: "Clients list title",
  },
});

export default messages;
