import React from "react";
// import { Provider } from "react-redux";

// import createStore from "./src/state/createStore";
import { IntlProvider } from "react-intl";

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  // const store = createStore();
  return (
    <IntlProvider locale="fr" defaultLocale="fr">
      {element}
    </IntlProvider>
  ); // <Provider store={store}>{element}</Provider>;
};
