import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

class Page extends React.Component<PageProps, PageState> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  render() {
    return (
      <div
        className={
          "box-wrapper " + (this.props.className ? this.props.className : "")
        }
      >
        {this.props.sectionName && (
          <h2 className="box-title">
            <FormattedMessage {...this.props.sectionName} />
          </h2>
        )}
        <div
          dangerouslySetInnerHTML={{ __html: this.props.content }}
          className="box"
        ></div>
      </div>
    );
  }
}

export interface PageProps {
  className: string | undefined;
  sectionName: ReactElement;
  content: string;
}
interface PageState {
  isLoaded: boolean;
}

export default Page;
