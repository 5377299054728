import React from "react";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHome: false,
    };
  }

  render() {
    return null;
  }
}

export default Index;
