import React from "react";
const NotFound = () => {
  return (
    <div>
      <div
        className="yeasts-container"
        style={{
          textAlign: "center",
        }}
      >
        <h1
          style={{
            fontSize: "15vw",
            margin: 0,
          }}
        >
          404
        </h1>
        <h2 style={{ fontSize: "2vw" }}>Page non trouvée</h2>
      </div>
    </div>
  );
};
export default NotFound;
