import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { IconName } from "@fortawesome/fontawesome-svg-core";

const alcohol = {
  prefix: "fas", // probably better to use a custom one
  iconName: "alcohol" as IconName,
  icon: [
    512, // viewBox width
    512, // viewBox height
    [], // ligatures
    "e000", // unicode codepoint - private use area
    "m 373.39062,-1.2988281 c -78.36777,6.5e-6 -142.38476,64.0150381 -142.38476,142.3828081 10e-6,78.36778 64.01699,142.38476 142.38476,142.38477 78.36778,0 142.38281,-64.01699 142.38282,-142.38477 0,-78.367775 -64.01504,-142.3828081 -142.38282,-142.3828081 z m 0,45.3535161 c 53.85648,-10e-7 97.0293,43.172817 97.0293,97.029292 0,53.85648 -43.17282,97.0293 -97.0293,97.0293 -53.85647,0 -97.02929,-43.17282 -97.02929,-97.0293 0,-53.856471 43.17282,-97.029288 97.02929,-97.029292 z M 117.18555,185.19336 c -60.403724,0 -109.8554691,49.4537 -109.8554719,109.85742 -4.1e-6,60.40373 49.4517439,109.85743 109.8554719,109.85742 60.40372,0 109.85742,-49.4537 109.85742,-109.85742 0,-60.40372 -49.45371,-109.85742 -109.85742,-109.85742 z m 0,45.35547 c 35.89241,0 64.5039,28.60953 64.5039,64.50195 1e-5,35.89242 -28.61148,64.50195 -64.5039,64.50195 -35.892426,10e-6 -64.501959,-28.60953 -64.501956,-64.50195 10e-7,-35.89242 28.609533,-64.50195 64.501956,-64.50195 z m 234.67968,109.18359 c -46.53309,0 -84.74219,38.20909 -84.74218,84.74219 0,46.53309 38.20909,84.74218 84.74218,84.74219 46.5331,-10e-6 84.74024,-38.2091 84.74024,-84.74219 0,-46.5331 -38.20714,-84.74219 -84.74024,-84.74219 z m 0,45.35547 c 22.0218,0 39.38673,17.36492 39.38672,39.38672 0,22.02179 -17.36492,39.38672 -39.38672,39.38672 -22.02179,0 -39.38867,-17.36493 -39.38867,-39.38672 0,-22.0218 17.36688,-39.38672 39.38867,-39.38672 z",
  ],
} as IconDefinition;

export default alcohol;
