import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import messages from "./messages";

import "../../sass/_clients-list.scss";

class ClientsList extends React.Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.state = {
      isLoaded: false,
      content: null,
    };
  }

  setClient(client) {
    this.props.setMapPopup(client);
  }

  render() {
    const { clients } = this.props;
    return (
      <div className="box-wrapper">
        <h2 className="box-title">
          <FormattedMessage
            values={{
              number: clients ? clients.length : "",
            }}
            {...messages.clients_title}
          />
        </h2>
        <div className="box client-list">
          {clients
            .filter((c) => c.meta != null)
            .map((client) => {
              return (
                <div className="client" key={client.id}>
                  <header>
                    <h3>{client.title}</h3>
                    {/* client.picture &&
									<img src={client.picture} /> */}
                    {client.meta.url && (
                      <a href={client.meta.url}>{client.meta.url}</a>
                    )}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setClient(client);
                      }}
                      className="address"
                    >
                      {client.meta.localisation.streetAddress}
                    </a>
                  </header>
                  <div
                    dangerouslySetInnerHTML={{ __html: client.content }}
                  ></div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

ClientsList.propTypes = {
  setMapPopup: PropTypes.func,
  clients: PropTypes.array,
};

export default ClientsList;
