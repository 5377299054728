/* global requestAnimationFrame */
import Yeast from "./Yeast";

const OPTIONS = {
  radiusX_min: 40,
  radiusX_max: 60,
  radiusY_min: 50,
  radiusY_max: 70,
  v_min: 20,
  v_max: 90,
};

const GRID_SIZE = 300;
const REPRODUCE_THRESHOLD = 2;
const START_YEASTS = 20;

class Background {
  constructor(canvas, img) {
    window.requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame;

    if (!canvas.getContext) {
      return false;
    }

    this.yeasts = [];

    this.canvas = canvas;
    this.ctx = canvas.getContext("2d");
    this.ctx.globalCompositeOperation = "xor";

    this.pattern = this.ctx.createPattern(img, "repeat");

    window.addEventListener("resize", () => this.win_resize());
    this.win_resize();

    this.offset = 0;

    this.init();

    requestAnimationFrame(() => this.draw());
  }

  win_resize() {
    this.canvas.height = this.canvas.clientHeight;
    this.canvas.width = this.canvas.clientWidth;
    // ensure one row below and above the canvs
    this.grid_rows = Math.ceil(this.canvas.height / GRID_SIZE) + 2;
    this.grid_columns = Math.ceil(this.canvas.width / GRID_SIZE) + 2;
  }

  init() {
    for (let i = 0; i < START_YEASTS; i++) {
      let x = Math.random() * this.canvas.width;
      let y = Math.random() * this.canvas.height;
      let yeast = new Yeast(x, y, OPTIONS, this.pattern);
      yeast.init();
      this.yeasts.push(yeast);
    }
  }

  draw_yeasts() {
    let weights_matrix = Array(this.grid_rows)
      .fill()
      .map(() => Array(this.grid_columns).fill(0));
    this.yeasts = this.yeasts.filter((yeast, i) => {
      if (this.is_out_canvas(yeast)) {
        return false;
      }
      yeast.move(i);
      let column = Math.floor(yeast.x / GRID_SIZE) + 1;
      let row = Math.floor(yeast.y / GRID_SIZE) + 1;
      weights_matrix[row][column]++;
      yeast.draw(this.ctx);
      return true;
    });
    this.yeasts.forEach((yeast) => {
      let column = Math.floor(yeast.x / GRID_SIZE) + 1;
      let row = Math.floor(yeast.y / GRID_SIZE) + 1;
      if (weights_matrix[row][column] < REPRODUCE_THRESHOLD) {
        let child = yeast.reproduce();
        if (child) {
          this.yeasts.unshift(child);
        }
      }
    });
  }

  draw() {
    const ctx = this.ctx;

    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.draw_yeasts();

    requestAnimationFrame(() => this.draw());
  }

  is_out_canvas(y) {
    let radius = Math.max(y.radiusX, y.radiusY);
    if (
      y.y + radius < 0 ||
      y.x + radius < 0 ||
      y.y - radius > this.canvas.height ||
      y.x - radius > this.canvas.width
    ) {
      return true;
    }
    return false;
  }
}

export default Background;
