import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { graphql, PageProps } from "gatsby";
import type { PageProps as LevaturaPageProps } from "../../components/Page/index";

import messages from "../../components/yeasts/messages";

import Page from "../../components/Page";
import ExpandableList from "../../components/ExpandableList";
import YeastsFilter, { WpTaxonomy } from "../../components/yeasts/YeastsFilter";
import YeastComponent from "../../components/yeasts/Yeast";
import type { Yeast } from "../../components/yeasts/Yeast";

import "../../sass/_yeasts.scss";
import PreviousOrders from "../../components/yeasts/PreviousOrders";

const DISABLED_POINTS = 1;

class Yeasts extends React.Component<PageProps<YeastsProps>, YeastsState> {
  constructor(props: PageProps<YeastsProps>) {
    super(props);
    // this.url = props.router.pathname;
    const points = {};
    for (const yeast of props.data.yeasts.nodes) {
      points[yeast.id] = { points: {}, points_sum: 1 };
    }
    this.state = {
      // parsed_qs: props.initial_qs ? props.initial_qs : {},
      parsed_qs: {}, // FIXME
      quantity: false,
      points: points,
      sorted_yeasts: props.data.yeasts.nodes,
    };

    /*if (Object.getOwnPropertyNames(props.initial_qs).length !== 0) {
      this.filter_yeasts(props.initial_qs, true);
    }*/
  }

  /*
  static async getInitialProps(ctx) {

    /*
    let qs = ctx.query;
    let qs2 = {};
    for (let prop in qs) {
      qs2[prop] = JSON.parse(qs[prop]);
    }

    return {
      header_page: page,
      yeasts: yeasts,
      types: types,
      species: species,
      flocculations: flocculations,
      initial_qs: qs2,
    };
  }
    */

  componentDidMount() {
    // TODO move before?
  }

  overlap(min1: number, max1: number, min2: number, max2: number) {
    // https://stackoverflow.com/a/16691814
    return Math.min(max1, max2) - Math.max(min1, min2);
  }

  filter_yeasts(criterias, init = false) {
    const yeasts = this.props.data.yeasts.nodes;
    const { points } = this.state;
    // const qs = this.props.router.query;
    for (const prop in criterias) {
      const criteria = criterias[prop];
      // one point if the criteria is set to default/disabled
      if (criteria.type === "disabled") {
        yeasts.forEach((yeast) => {
          points[yeast.id].points[prop] = DISABLED_POINTS;
        });
        if (!init) {
          // delete qs[prop];
        }
      } else if (criteria.type === "range") {
        // length of the range
        criteria.l = criteria.max - criteria.min;
        yeasts.forEach((yeast) => {
          if (yeast.meta[prop + "Min"] && yeast.meta[prop + "Max"]) {
            points[yeast.id].points[prop] = 0;
            // Check that there is an intersection
            const overlap = this.overlap(
              criteria.min,
              criteria.max,
              yeast.meta[prop + "Min"],
              yeast.meta[prop + "Max"]
            );

            points[yeast.id].points[prop] = overlap;
          } else {
            points[yeast.id].points[prop] = DISABLED_POINTS;
          }
        });
        if (!init) {
          // qs[prop] = JSON.stringify(criteria);
        }
      } else if (criteria.type === "select") {
        const selected_terms = new Set(criteria.values);
        yeasts.forEach((yeast) => {
          let compare_with = [];
          switch (prop) {
            case "yeasts_types":
              compare_with = yeast.types.nodes.map((n) => n.slug);
              break;
            case "yeasts_species":
              compare_with = yeast.species.nodes.map((n) => n.slug);
              break;
            case "yeast_flocculation":
              compare_with = yeast.flocculation.nodes.map((n) => n.slug);
              break;
          }

          const intersection = [...selected_terms].filter(
            (x) => compare_with.indexOf(x) !== -1
          );
          // set points to something so small that it isn't displayed if not belonging to the right cat.
          const distance =
            intersection.length === 0 ? -1000 : intersection.length;
          points[yeast.id].points[prop] = distance;
        });
        if (!init) {
          // qs[prop] = JSON.stringify(criteria);
        }
      }
    }
    if (!init) {
      // const href = this.props.router.pathname + "?" + queryString.stringify(qs);
      // const as = href;
      // Router.push(href, as, { shallow: true }); TODO
    }
    // Sum the points
    yeasts.forEach((yeast) => {
      points[yeast.id].points_sum = 0;
      for (const propPoint of Object.values(points[yeast.id].points)) {
        points[yeast.id].points_sum += propPoint;
      }
    });
    // Mutate directly if we are in getInitialProps
    const sorted_yeasts = yeasts
      .filter((yeast) => points[yeast.id].points_sum > 0)
      .sort((a, b) => {
        // most relevants yeasts firsts
        if (points[a.id].points_sum > points[b.id].points_sum) {
          return -1;
        }
        if (points[a.id].points_sum < points[b.id].points_sum) {
          return 1;
        }

        // if we can't find the most relevants by points, use wordpress menu order
        if (a.menuOrder > b.menuOrder) {
          return 1;
        }
        if (a.menuOrder < b.menuOrder) {
          return -1;
        }

        // If we can't have an ordering, randomize it so the order isn't always the same
        return Math.random() * 2 - 1;
      });
    this.setState({
      ...this.state,
      points: points,
      sorted_yeasts: sorted_yeasts,
    });
  }

  quantity_change(val) {
    this.setState({ quantity: val });
  }

  render() {
    const {
      types: { nodes: types },
      species: { nodes: species },
      flocculations: { nodes: flocculations },
      headerPage,
    } = this.props.data;
    const { sorted_yeasts } = this.state;

    return (
      <div>
        <Page className="header-yeasts" {...headerPage} />
        <PreviousOrders />
        <div className="yeasts-container box-wrapper">
          <h2 className="box-title">
            <FormattedMessage {...messages.yeast_filter_title} />
          </h2>

          <YeastsFilter
            defaults={this.state.parsed_qs}
            handler={this.filter_yeasts.bind(this)}
            types={types}
            species={species}
            flocculations={flocculations}
            quantity_change={this.quantity_change.bind(this)}
          />
          <h2 className="box-title">
            <FormattedMessage {...messages.yeasts_list_title} />
          </h2>
          <ExpandableList className="yeast">
            {
              // Order the yeasts, most relevants first
              sorted_yeasts.map((yeast) => {
                return (
                  // yeast.points_sum > 0 && (
                  // @ts-ignore
                  <YeastComponent
                    {...yeast}
                    quantity={this.state.quantity}
                    key={yeast.id}
                  />
                  // )
                );
              })
            }
          </ExpandableList>
        </div>
      </div>
    );
  }
}

interface YeastsState {
  quantity: number | false;
  parsed_qs: object;
  points: {
    [key in string]: {
      points: object;
      points_sum: number;
    };
  };
  sorted_yeasts: Yeast[];
}
interface YeastsProps {
  yeasts: {
    nodes: Yeast[];
  };
  species: {
    nodes: WpTaxonomy[];
  };
  flocculations: {
    nodes: WpTaxonomy[];
  };
  types: {
    nodes: WpTaxonomy[];
  };
  headerPage: LevaturaPageProps;
}
/*
Yeasts.propTypes = {
  router: PropTypes.object,
  "router.pathname": PropTypes.string,
  "router.query": PropTypes.string,
  initial_qs: PropTypes.object,
  yeasts: PropTypes.array,
  types: PropTypes.array,
  species: PropTypes.array,
  flocculations: PropTypes.array,
  header_page: PropTypes.object,
};
*/

export const query = graphql`
  query Yeasts {
    yeasts: allWpYeast {
      nodes {
        databaseId
        id
        slug
        title
        menuOrder
        content

        meta: yeasts {
          alcoholMax
          alcoholMin
          attenuationMax
          attenuationMin
          temperatureMax
          temperatureMin
          densityMin
          densityMax
          price
        }
        species: yeastsSpecies {
          nodes {
            slug
            name
          }
        }
        types: yeastsTypes {
          nodes {
            slug
            name
          }
        }
        flocculation: yeastsFlocculations {
          nodes {
            slug
            name
          }
        }
      }
    }
    headerPage: wpPage(databaseId: { eq: 35 }) {
      id
      title
      content
    }
    flocculations: allWpYeastsFlocculation {
      nodes {
        label: name
        parentId
        id
        value: slug
      }
    }
    types: allWpYeastsType {
      nodes {
        label: name
        parentId
        id
        value: slug
      }
    }
    species: allWpYeastsSpecie {
      nodes {
        label: name
        parentId
        id
        value: slug
      }
    }
  }
`;

/* @ts-ignore */
Yeasts.title = "Nos levures";
export default Yeasts;
