import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "gatsby";
// import { withRouter } from "next/router";

import messages from "../../messages";

class Nav extends React.PureComponent {
  render() {
    return (
      <nav className={"menu " + (this.props.isHome ? "home-menu" : "box")}>
        <Link to="/" className="title">
          <h1>
            <FormattedMessage {...messages.title} />
          </h1>
        </Link>
        <h2 className="moto">
          <FormattedMessage {...messages.slogan} />
        </h2>
        <ul>
          <li>
            <div className="pseudo-parent"></div>
            <Link to="/about">
              <FormattedMessage {...messages.about} />
            </Link>
          </li>
          <li>
            <div className="pseudo-parent"></div>
            <Link to="/yeasts">
              <FormattedMessage {...messages.yeasts} />
            </Link>
          </li>
          {/*					<li>
						<div className="pseudo-parent"></div>
						<Link to="/events"><a><FormattedMessage {...messages.events} /></a></Link>
          </li> */}
          <li>
            <div className="pseudo-parent"></div>
            <Link to="/clients">
              <FormattedMessage {...messages.clients} />
            </Link>
          </li>
          <li>
            <div className="pseudo-parent"></div>
            <Link to="/project">
              <FormattedMessage {...messages.project} />
            </Link>
          </li>
          {/*
          <li>
            <div className="pseudo-parent"></div>
            <Link to="/wine">
                <FormattedMessage {...messages.wine} />
            </Link>
          </li>
          */}
          <li>
            <div className="pseudo-parent"></div>
            <Link to="/contact">
              <FormattedMessage {...messages.contact} />
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

Nav.propTypes = {
  isHome: PropTypes.bool,
};

export default Nav;
