import React, { ReactElement, RefObject } from "react";

type children = Iterable<ReactElement<ExpandableListItems>>;

interface ExpandableListProps<T> {
  className: string;
  children: children;
}
interface ExpandableListState {
  columns: any;
  open_yeast: string | false;
}

interface ExpandableListItems extends ExpandableListItemWrapperInjectedProps {
  id: string;
}

interface ExpandableListItemWrapperInjectedProps {
  on_toggle: () => void;
  open: boolean;
}

class ExpandableList<T> extends React.Component<
  ExpandableListProps<T>,
  ExpandableListState
> {
  private list: RefObject<HTMLDivElement>;

  constructor(props: ExpandableListProps<T>) {
    super(props);

    this.list = React.createRef();
    this.state = {
      columns: null,
      open_yeast: false,
    };
  }

  componentDidMount() {
    this.updateSize();
    window.addEventListener("resize", this.updateSize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSize.bind(this));
  }

  updateSize() {
    this.setState({ columns: this.get_columns_number() });
  }

  get_columns_number() {
    // delete "
    if (this.list.current === null) {
      return;
    }
    return parseInt(
      window
        .getComputedStyle(this.list.current, "::before")
        .content.slice(1, -1)
    );
  }

  yeast_toggle(id, isOpen) {
    this.setState(() => ({
      open_yeast: isOpen ? id : false,
    }));
  }

  render() {
    const children: children = this.props.children;
    let i = 0;
    return (
      <div ref={this.list} className={this.props.className + "-list"}>
        {
          // @ts-ignore
          React.Children.map(children, (yeast) => {
            if (yeast === null) {
              return;
            }
            i++;
            let c = 0;
            const isOpen = yeast.props.id === this.state.open_yeast;
            if (isOpen) {
              c = this.state.columns - (i % this.state.columns);
            }

            return /* yeast.points_sum > 0 && */ [
              [...Array(c).keys()].map((k) => {
                return (
                  <div
                    key={"fake-" + i + "-" + k}
                    className={"fake-" + this.props.className}
                  ></div>
                );
              }),
              React.cloneElement(yeast, {
                on_toggle: this.yeast_toggle.bind(this),
                open: isOpen,
              }),
            ];
          })
        }
      </div>
    );
  }
}
export default ExpandableList;
