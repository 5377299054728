import React from "react";

import Range from "rc-slider";
import { roundTo } from "round-to";
import { FormattedMessage } from "react-intl";

import styles from "../../../sass_vars.js";

const YeastsFilterRange: React.FC<YeastsFilterRangeProps> = (props) => {
  return (
    <div className="yeasts-filter-range">
      <p>
        <FormattedMessage
          {...props.title}
          values={{
            min: roundTo(props.values.min, 1),
            max: roundTo(props.values.max, 1),
          }}
        />
      </p>
      <div>
        <span className="min">{props.config.min}</span>
        <Range
          range
          trackStyle={[{ backgroundColor: styles["$color-accent"] }, {}]}
          railStyle={{ backgroundColor: styles["$grey"] }}
          handleStyle={[
            { borderColor: styles["$color-accent"] },
            { borderColor: styles["$color-accent"] },
          ]}
          allowCross={false}
          defaultValue={[
            ((props.values.min - props.config.min) / props.config.max) * 100,
            (props.values.max / props.config.max) * 100,
          ]}
          step={0.1}
          onChange={props.onChange}
        />
        <span className="max">{props.config.max}</span>
      </div>
    </div>
  );
};

interface Range {
  min: number;
  max: number;
}
interface YeastsFilterRangeProps {
  title: object;
  values: Range;
  config: Range;
  onChange: (value: number | number[]) => void;
}

export type { Range };
export default YeastsFilterRange;
