import React from "react";
import PropTypes from "prop-types";

import Biography from "./Biography";

import "../../../sass/_biographies.scss";

class Biographies extends React.Component {
  render() {
    const { biographies } = this.props;

    return (
      <div className="biographies-container box-wrapper">
        <div className="biographies-list">
          {biographies.map((biography) => (
            <Biography {...biography} key={biography.id} />
          ))}
        </div>
      </div>
    );
  }
}

Biographies.propTypes = {
  biographies: PropTypes.array,
};

export default Biographies;
