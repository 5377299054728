import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

interface BiographyProps {
  featuredImage: any;
  title: string;
  content: string;
  key: string;
}
class Biography extends React.Component<BiographyProps> {
  render() {
    const image = getImage(this.props.featuredImage.node.localFile);
    return (
      <div className="single-biography box">
        <div className="pic-container">
          <GatsbyImage image={image} alt={this.props.title} className="image" />
        </div>
        <h3>{this.props.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
      </div>
    );
  }
}

export default Biography;
