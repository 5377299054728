import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "site_name",
    defaultMessage: "Levatura SA",
  },
  home: {
    id: "home",
    defaultMessage: "Accueil",
  },
  contact: {
    id: "contact",
    defaultMessage: "Contact",
  },
  yeasts: {
    id: "yeasts",
    defaultMessage: "Levures",
  },
  events: {
    id: "events",
    defaultMessage: "Événements",
  },
  clients: {
    id: "clients",
    defaultMessage: "Nos clients",
  },
  project: {
    id: "projects",
    defaultMessage: "Projet",
  },
  wine: {
    id: "wine",
    defaultMessage: "Vin",
  },
  about: {
    id: "about_us",
    defaultMessage: "Qui sommes-nous ?",
  },
  slogan: {
    id: "motto",
    defaultMessage: "La levure sur mesure",
  },
});

export default messages;
