import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { IconName } from "@fortawesome/fontawesome-svg-core";

const alcohol: IconDefinition = {
  prefix: "fas", // probably better to use a custom one
  iconName: "flocculation" as IconName,
  icon: [
    512, // viewBox width
    512, // viewBox height
    [], // ligatures
    "e001", // unicode codepoint - private use area
    "M 430.30469 131.91406 A 52.563688 52.563688 0 0 0 377.74023 184.47852 A 52.563688 52.563688 0 0 0 430.30469 237.04102 A 52.563688 52.563688 0 0 0 482.86719 184.47852 A 52.563688 52.563688 0 0 0 430.30469 131.91406 z M 274.59766 192.79297 A 56.882952 56.882952 0 0 0 217.71484 249.67578 A 56.882952 56.882952 0 0 0 274.59766 306.55859 A 56.882952 56.882952 0 0 0 331.48047 249.67578 A 56.882952 56.882952 0 0 0 274.59766 192.79297 z M 113.01172 214.76172 A 56.882952 56.882952 0 0 0 56.128906 271.64453 A 56.882952 56.882952 0 0 0 113.01172 328.52734 A 56.882952 56.882952 0 0 0 169.89453 271.64453 A 56.882952 56.882952 0 0 0 113.01172 214.76172 z M 415.91016 343.17969 A 72.071643 72.071643 0 0 0 343.83984 415.25195 A 72.071643 72.071643 0 0 0 415.91016 487.32422 A 72.071643 72.071643 0 0 0 487.98242 415.25195 A 72.071643 72.071643 0 0 0 415.91016 343.17969 z M 97.15625 348.97461 A 72.071643 72.071643 0 0 0 25.083984 421.04492 A 72.071643 72.071643 0 0 0 97.15625 493.11719 A 72.071643 72.071643 0 0 0 169.22852 421.04492 A 72.071643 72.071643 0 0 0 97.15625 348.97461 z M 247.73633 364.64844 A 63.819842 63.819842 0 0 0 183.91602 428.46875 A 63.819842 63.819842 0 0 0 247.73633 492.28906 A 63.819842 63.819842 0 0 0 311.55664 428.46875 A 63.819842 63.819842 0 0 0 247.73633 364.64844 z ",
  ],
};

export default alcohol;
