import { defineMessages } from "react-intl";

const messages = defineMessages({
  alcohol: {
    defaultMessage: "Alcool",
    description: "Alcool",
  },
  alcohol_interval: {
    defaultMessage: "{min}% - {max}%",
    description: "Interval d'alcool",
  },
  alcohol_interval_title: {
    defaultMessage: "Alcool: {min}% - {max}%",
    description: "Interval d'alcool",
  },
  temperature: {
    defaultMessage: "Température",
    description: "Température",
  },
  temperature_interval: {
    defaultMessage: "{min}°C - {max}°C",
    description: "Interval de température",
  },
  temperature_interval_title: {
    defaultMessage: "Température: {min}°C - {max}°C",
    description: "Interval de température",
  },
  density: {
    defaultMessage: "Densité initiale",
    description: "Densité initiale",
  },
  density_interval: {
    defaultMessage: "{min} - {max}",
    description: "Interval de densité initiale",
  },
  density_interval_title: {
    defaultMessage: "Densité initiale: {min} - {max}",
    description: "Interval de densité initiale",
  },
  quantity_input_title: {
    defaultMessage: "Taille du brassin: ",
    description: "Quantité de levure",
  },
  attenuation: {
    defaultMessage: "Atténuation",
    description: "Atténuation",
  },
  attenuation_interval: {
    defaultMessage: "{min} - {max}",
    description: "Interval d'atténuation",
  },
  attenuation_interval_title: {
    defaultMessage: "Atténuation: {min} - {max}",
    description: "Interval d'atténuation",
  },
  order: {
    defaultMessage: "Commander",
    description: "Order button",
  },
  yeast_filter_title: {
    defaultMessage: "Vos données de base",
    description: "Yeast filter title",
  },
  yeasts_list_title: {
    defaultMessage: "Nos propositions",
    description: "Yeasts list title",
  },
  flocculation: {
    defaultMessage: "Floculation",
    description: "Flocculation",
  },
});

export default messages;
