import EllipticGradient from "./EllipticGradient";

const TWO_PI = 2 * Math.PI;

const ADULT = {
  r: 223,
  g: 162,
  b: 0,
};
const CHILD = {
  r: 0,
  g: 0,
  b: 0,
};

// https://gist.github.com/gre/1650294
function easeInOutCubic(t) {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
}

function gaussianRand() {
  var rand = 0;

  for (var i = 0; i < 6; i += 1) {
    rand += Math.random();
  }

  return rand / 6;
}
function gaussianRandom(start, end) {
  return Math.floor(start + gaussianRand() * (end - start + 1));
}

class Yeast {
  constructor(x, y, options, pattern, isChild = false) {
    this.options = options;
    this.color = ADULT;
    this.pattern = pattern;
    this.x = x;
    this.y = y;
    this.isChild = isChild;

    this.isBudding = false;
    this.grow_counter = 0;
    this.speed_counter = 0;

    if (!isChild) {
      this.growing_steps = 1;
      this.grow_counter = 1;
      this.speed_steps = 1;
      this.speed_counter = 1;
    }
  }

  init() {
    this.init_speed();
    this.init_radius();
    this.init_rotation();
  }

  init_speed() {
    this.vx =
      ((Math.random() >= 0.5 ? 1 : -1) *
        gaussianRandom(this.options.v_min, this.options.v_max)) /
      100;
    this.vy =
      ((Math.random() >= 0.5 ? 1 : -1) *
        gaussianRandom(this.options.v_min, this.options.v_max)) /
      100;
  }

  init_radius(
    x_min = this.options.radiusX_min,
    x_max = this.options.radiusX_max,
    y_min = this.options.radiusY_min,
    y_max = this.options.radiusY_max
  ) {
    this.radiusX = gaussianRandom(x_min, x_max);
    this.radiusY = gaussianRandom(y_min, y_max);
  }

  init_rotation() {
    this.rotation = Math.random() * Math.PI;
  }

  move() {
    this.prev_x = this.x;

    this.x += this.vx;
    this.y += this.vy;
  }

  draw(ctx) {
    ctx.beginPath();
    ctx.ellipse(
      this.x,
      this.y,
      this.radiusX - 2,
      this.radiusY - 2,
      this.rotation,
      0,
      TWO_PI
    );
    ctx.fillStyle = this.get_color();
    ctx.fill();

    ctx.beginPath();
    ctx.ellipse(
      this.x,
      this.y,
      this.radiusX - 2,
      this.radiusY - 2,
      this.rotation,
      0,
      TWO_PI
    );
    ctx.fillStyle = this.pattern;

    ctx.save();
    ctx.translate(this.x, this.y);
    ctx.fill();
    ctx.restore();

    EllipticGradient.draw(
      ctx,
      this.x,
      this.y,
      this.radiusX,
      this.radiusY,
      this.rotation,
      this.grow_counter / this.growing_steps
    );

    if (this.isChild) {
      this.grow();
    }
  }

  grow() {
    // has completely grow, can reproduce now
    if (
      this.grow_counter === this.growing_steps &&
      this.speed_counter === this.speed_steps
    ) {
      delete this.color_diff;
      delete this.vx_diff;
      delete this.vy_diff;
      delete this.vx_initial;
      delete this.vy_initial;
      delete this.radiusY_diff;
      delete this.radiusX_diff;
      delete this.radiusY_initial;
      delete this.radiusX_initial;
      this.isChild = false;
      return;
    }

    if (this.grow_counter !== this.growing_steps) {
      let progression = easeInOutCubic(this.grow_counter / this.growing_steps);

      this.color = {
        r: CHILD.r + progression * this.color_diff.r,
        g: CHILD.g + progression * this.color_diff.g,
        b: CHILD.b + progression * this.color_diff.b,
      };

      this.radiusX = this.radiusX_initial + progression * this.radiusX_diff;
      this.radiusY = this.radiusY_initial + progression * this.radiusY_diff;

      this.grow_counter++;
    }
    if (this.speed_counter !== this.speed_steps) {
      let progression = easeInOutCubic(this.speed_counter / this.speed_steps);

      this.vx = this.vx_initial + progression * this.vx_diff;
      this.vy = this.vy_initial + progression * this.vy_diff;

      this.speed_counter++;
    }
  }

  reproduce() {
    if (this.isChild || Math.random() < 0.9) {
      return;
    }
    let child = new Yeast(this.x, this.y, this.options, this.pattern, true);
    child.init_radius(
      (1 / 3) * this.radiusX,
      (2 / 3) * this.radiusX,
      (1 / 3) * this.radiusY,
      (2 / 3) * this.radiusY
    );
    let x =
      this.x + (Math.random() >= 0.5 ? 1 : -1) * (this.radiusX - child.radiusX); //* (gaussianRandom(5, 12)/10)*this.radiusX;
    let y =
      this.y + (Math.random() >= 0.5 ? 1 : -1) * (this.radiusY - child.radiusY); //* (gaussianRandom(5, 12)/10)*this.radiusY;
    child.x = x;
    child.y = y;
    child.vx = this.vx;
    child.vy = this.vy;

    child.init_rotation();

    child.growing_steps = gaussianRandom(250, 450);
    child.speed_steps = gaussianRandom(100, 250);

    // oposite of parent
    let vector_x = Math.sign(x - this.x);
    let vector_y = Math.sign(y - this.y);

    // Calculate speed differences in order to calculate the progression
    child.vx_initial = child.vx;
    let final_vx =
      (vector_x * gaussianRandom(this.options.v_min, this.options.v_max)) / 100;
    child.vx_diff = final_vx - child.vx;
    child.vy_initial = child.vy;
    let final_vy =
      (vector_y * gaussianRandom(this.options.v_min, this.options.v_max)) / 100;
    child.vy_diff = final_vy - child.vy;

    // Calculate radiuses differences in order to calculate the progression
    child.radiusX_initial = child.radiusX;
    let radiusX_final = gaussianRandom(
      this.options.radiusX_min,
      this.options.radiusX_max
    );
    child.radiusX_diff = radiusX_final - child.radiusX;
    child.radiusY_initial = child.radiusY;
    let radiusY_final = gaussianRandom(
      this.options.radiusY_min,
      this.options.radiusX_min
    );
    child.radiusY_diff = radiusY_final - child.radiusY;

    child.color = CHILD;

    child.color_diff = {
      r: ADULT.r - CHILD.r,
      g: ADULT.g - CHILD.g,
      b: ADULT.b - CHILD.b,
    };

    return child;
  }

  get_color() {
    return (
      "rgb(" + this.color.r + ", " + this.color.g + ", " + this.color.b + ")"
    );
  }
}

export default Yeast;
