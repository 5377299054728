import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Biographies from "../components/about/Biographies";
import Community from "../components/about/Community";
import Page from "../components/Page";

import messages from "../components/about/messages";

class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { description, biographies, press, community } = this.props.data;
    return (
      <div>
        <Page {...description} />
        <Biographies biographies={biographies.nodes} />
        <Page {...press} sectionName={messages.press_title} />
        <Community community={community.nodes} />
      </div>
    );
  }
}

About.title = "À propos de nous";

export const query = graphql`
  query About {
    description: wpPage(databaseId: { eq: 40 }) {
      id
      title
      content
    }
    press: wpPage(databaseId: { eq: 599 }) {
      id
      title
      content
    }
    biographies: allWpBiography {
      nodes {
        id
        title
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    community: allWpCommunityMember {
      nodes {
        title
        id
        featuredImage {
          node {
            localFile {
              thumbnail: childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  transformOptions: { cropFocus: CENTER }
                  aspectRatio: 1.3
                  placeholder: BLURRED
                )
              }
              full: childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        content
      }
    }
  }
`;
export default About;
