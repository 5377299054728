import React from "react";
import PropTypes from "prop-types";
import Layout from "./src/components/Layout";

import wrapWithProvider from "./wrap-with-provider";
// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = wrapWithProvider;

wrapPageElement.propTypes = {
  element: PropTypes.object,
  props: PropTypes.object,
};
