import React, { RefObject } from "react";
import { FormattedMessage } from "react-intl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { roundTo } from "round-to";

import messages from "./messages";

import flocculation from "../../icons/flocculation";
import alcohol from "../../icons/alcohol_bubbles";

import { OrderLink } from "./utils";

class YeastComponent extends React.Component<YeastProps, YeastState> {
  private ref: RefObject<HTMLDivElement>;
  constructor(props: YeastProps) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      // yeast_id: props.match.params.yeast_id
      isOpen: this.props.open === undefined ? false : this.props.open,
    };
  }

  // TODO update
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.isOpen) {
      this.setState({ isOpen: nextProps.open }, function () {});
    }
  }

  open() {
    if (this.props.showOpenButton == false) return;
    if (this.state.isOpen) {
      return;
    }
    const isOpen = true;
    if (this.props.on_toggle) this.props.on_toggle(this.props.id, isOpen);
  }

  toggle_button() {
    const isOpen = !this.state.isOpen;
    if (this.props.on_toggle) this.props.on_toggle(this.props.id, isOpen);
  }

  render() {
    return (
      <div
        onClick={this.open.bind(this)}
        ref={this.ref}
        className={"box single-yeast" + (this.state.isOpen ? " open" : "")}
      >
        <div className="single-yeast-wrapper">
          <header>
            <h3>{this.props.title}</h3>
            {this.props.showOpenButton !== false && (
              <div
                onClick={this.toggle_button.bind(this)}
                className="toggle_button"
              ></div>
            )}
          </header>

          <main>
            <div className="props">
              <div className="species">
                {Object.values(
                  this.props.species.nodes.map((n) => n.name)
                ).join(", ")}
              </div>
              <div>
                {Object.values(this.props.types.nodes.map((n) => n.name)).join(
                  ", "
                )}
              </div>

              <table>
                <tbody>
                  {this.props.meta.alcoholMin && this.props.meta.alcoholMax && (
                    <tr>
                      <td>
                        <FontAwesomeIcon icon={alcohol} />
                      </td>
                      <td>
                        {this.state.isOpen && (
                          <FormattedMessage {...messages.alcohol} />
                        )}
                      </td>
                      <td>
                        <FormattedMessage
                          {...messages.alcohol_interval}
                          values={{
                            min: roundTo(this.props.meta.alcoholMin, 1),
                            max: roundTo(this.props.meta.alcoholMax, 1),
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {this.props.meta.temperatureMin &&
                    this.props.meta.temperatureMax && (
                      <tr>
                        <td>
                          <FontAwesomeIcon icon={solid("thermometer-half")} />
                        </td>
                        <td>
                          {this.state.isOpen && (
                            <FormattedMessage {...messages.temperature} />
                          )}
                        </td>
                        <td>
                          <FormattedMessage
                            {...messages.temperature_interval}
                            values={{
                              min: roundTo(this.props.meta.temperatureMin, 1),
                              max: roundTo(this.props.meta.temperatureMax, 1),
                            }}
                          />
                        </td>
                      </tr>
                    )}
                  {this.props.meta.attenuationMin &&
                    this.props.meta.attenuationMax && (
                      <tr>
                        <td>A</td>
                        <td>
                          {this.state.isOpen && (
                            <FormattedMessage {...messages.attenuation} />
                          )}
                        </td>
                        <td>
                          <FormattedMessage
                            {...messages.attenuation_interval}
                            values={{
                              min: roundTo(this.props.meta.attenuationMin, 1),
                              max: roundTo(this.props.meta.attenuationMax, 1),
                            }}
                          />
                        </td>
                      </tr>
                    )}
                  {Object.keys(this.props.flocculation.nodes).length > 0 && (
                    <tr>
                      <td>
                        <FontAwesomeIcon icon={flocculation} />
                      </td>
                      <td>
                        {this.state.isOpen && (
                          <FormattedMessage {...messages.flocculation} />
                        )}
                      </td>
                      <td>
                        {Object.values(
                          this.props.flocculation.nodes.map((n) => n.name)
                        ).join(", ")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {this.state.isOpen && (
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: this.props.content }}
              ></p>
            )}
            <OrderLink quantity={this.props.quantity} yeast={this.props} />
          </main>
        </div>
      </div>
    );
  }
}

interface YeastTaxonomy {
  name: string;
  slug: string;
}
interface YeastTaxonomies {
  nodes: Array<YeastTaxonomy>;
}
interface YeastState {
  isOpen: boolean;
}
interface Yeast {
  databaseId: number;
  id: string;
  title: string;
  species: YeastTaxonomies;
  types: YeastTaxonomies;
  flocculation: YeastTaxonomies;
  content: string;
  menuOrder: number;
  meta: {
    alcoholMin: number;
    alcoholMax: number;
    temperatureMin: number;
    temperatureMax: number;
    attenuationMin: number;
    attenuationMax: number;
    price: number;
  };
}

type YeastProps = {
  open?: boolean;
  showOpenButton?: boolean;
  on_toggle?: (id: string, isOpen: boolean) => void;
  quantity: number | false;
} & Yeast;

export type {
  YeastTaxonomies,
  YeastTaxonomy,
  Yeast,
  YeastProps as YeastComponentProps,
};
export default YeastComponent;
