import React, { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";

import Nav from "./Nav";
// import messages from "../messages";

import YeastBackground from "../../background";

// TODO split for each page/module
import "../../sass/style.scss";
import { graphql, useStaticQuery } from "gatsby";

// https://stackoverflow.com/a/52031405
// config.autoAddCss = false;

// Register React Intl's locale data for the user's locale in the browser. This
// locale data was added to the page by `pages/_document.js`. This only happens
// once, on initial page load in the browser.
//
/*
if (typeof window !== "undefined" && window.ReactIntlLocaleData) {
  Object.keys(window.ReactIntlLocaleData).forEach((lang) => {
    addLocaleData(window.ReactIntlLocaleData[lang]);
  });
}
*/
/*
Router.events.on("routeChangeStart", (url) => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());
*/
const Layout = ({ children, location, pageResources }) => {
  const backgroundRef = useRef(null);

  useEffect(() => {
    /* eslint no-new: 0 */
    new YeastBackground(backgroundRef.current);
  }, []);

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // const { children, initialNow, path } = this.props
  const isHome = location.pathname === "/";
  const pageTitle =
    pageResources !== undefined ? pageResources.component.title : undefined;

  return (
    <div>
      <title>
        {pageTitle ? `${pageTitle} | ` : ""}
        {data.site.siteMetadata.title}
      </title>
      <canvas id="background" ref={backgroundRef}></canvas>
      <div className={"content-wrapper-outer " + (isHome ? "home" : "")}>
        <div className="content-wrapper">
          <div>
            <Nav isHome={isHome} />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  "*": PropTypes.string,
  uri: PropTypes.string,
  path: PropTypes.string,
  children: PropTypes.object,
  "children.type": PropTypes.object,
  "children.type.content_wrapper_class": PropTypes.string,
  background: PropTypes.node,
};

export default Layout;
