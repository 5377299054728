import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import type { Yeast } from "./Yeast";
import { Link } from "gatsby";
import round_half from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import type { Order } from "./PreviousOrders";

import messages from "./messages";
interface OrderProps {
  yeast: Yeast;
  quantity: number | false;
}
interface OptionalOrder {
  yeast: Yeast;
  quantity: number | false;
}
export type { OptionalOrder };
const OrderLink: FC<OrderProps> = ({ yeast, quantity }) => {
  const order: OptionalOrder = {
    yeast: yeast,
    quantity: quantity,
  };
  return (
    <Link to="order" state={{ order: JSON.stringify(order) }} className="order">
      {quantity !== false && (
        <>
          <span className="price">
            {round_half(quantity * yeast.meta.price)}
            .-
          </span>
        </>
      )}
      <FontAwesomeIcon icon={solid("shopping-basket")} />
      &nbsp;
      <FormattedMessage {...messages.order} />
    </Link>
  );
};

export { OrderLink };

const computeYeastPrice = (yeast: Yeast, quantity: number) => {
  return round_half(quantity * yeast.meta.price);
};

export { computeYeastPrice };
