import { defineMessages } from "react-intl";

const messages = defineMessages({
  community_title: {
    id: "investors",
    defaultMessage: "Nos investisseurs",
    description: "Titre de la section investisseurs",
  },
  press_title: {
    id: "press",
    defaultMessage: "Presse",
    description: "Press section title",
  },
});

export default messages;
