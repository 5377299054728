import React, { useEffect, useState } from "react";
import localforae from "localforage";
import type { Yeast } from "./Yeast";
import YeastComponent from "./Yeast";
import { computeYeastPrice, OrderLink } from "./utils";

const PreviousOrders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  useEffect(() => {
    localforae.getItem("previousOrders").then((orders: Order[]) => {
      setOrders(orders);
    });
  }, []);
  return orders && orders.length > 0 ? (
    <div className="box-wrapper previous-orders yeast-container">
      <h2 className="box-title">Commandes précédents</h2>
      <div className="yeast-list">
        {orders.map((order) => (
          <YeastComponent
            {...order.yeast}
            key={order.yeast.id}
            open={false}
            quantity={order.quantity}
            showOpenButton={false}
          ></YeastComponent>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

const addOrder = (order: Order) => {
  localforae.getItem<Order[]>("previousOrders").then((orders) => {
    let updated: Order[];
    if (orders === null) {
      updated = [];
    } else {
      updated = orders;
    }
    localforae.setItem("previousOrders", [order, ...updated].slice(0, 4));
  });
};

interface Order {
  yeast: Yeast;
  quantity: number;
}

export default PreviousOrders;
export { addOrder };

export type { Order };
