import React from "react";
import { FormattedMessage } from "react-intl";
import type { DropdownData } from "./DropdownContainer";
import DropdownContainer from "./DropdownContainer";
import messages from "./messages";
import type { Range } from "./YeastsFilterRange";
import YeastsFilterRange from "./YeastsFilterRange";

interface WpTaxonomy {
  id: string;
  parentId: string;
  label: string;
  value: string;
}

export type { WpTaxonomy };

interface TreeTaxonomy extends WpTaxonomy, DropdownData {
  children: TreeTaxonomy[] | undefined;
}

class YeastsFilter extends React.Component<
  YeastsFilterProps,
  YeastsFilterState
> {
  unflatten(types: Array<WpTaxonomy>): Array<TreeTaxonomy> {
    const tree = [];
    const childOf = {};
    types.forEach((item: TreeTaxonomy) => {
      const { id, parentId } = item;
      childOf[id] = childOf[id] || [];
      item.children = childOf[id];
      parentId
        ? (childOf[parentId] = childOf[parentId] || []).push(item)
        : tree.push(item);
    });
    return tree;
  }

  private config: YeastsFilterRanges;
  private types: Array<TreeTaxonomy>;
  private species: Array<TreeTaxonomy>;
  private flocculations: Array<TreeTaxonomy>;
  constructor(props: YeastsFilterProps) {
    super(props);
    this.config = {
      alcohol: {
        min: 0,
        max: 20,
      },
      temperature: {
        min: 10,
        max: 30,
      },
      density: {
        min: 1,
        max: 1.5,
      },
      attenuation: {
        min: 0,
        max: 100,
      },
    };
    // deep clone object first
    this.state = {
      yeasts_types: {},
      yeasts_species: {},
      yeast_flocculation: {},
      ...Object.assign(Object.assign({}, this.config), this.props.defaults),
    };

    this.types = this.build_treeData(
      this.unflatten(this.props.types),
      this.state.yeasts_types
    )[0];

    this.species = this.build_treeData(
      this.unflatten(this.props.species),
      this.state.yeasts_species
    )[0];
    this.flocculations = this.build_treeData(
      this.unflatten(this.props.flocculations),
      this.state.yeast_flocculation
    )[0];

    this.quantity_change = this.quantity_change.bind(this);
  }

  componentDidUpdate(prevProps: YeastsFilterProps) {
    // TODO find another way
    if (
      JSON.stringify(prevProps.defaults) !== JSON.stringify(this.props.defaults)
    ) {
      this.setState(
        Object.assign(Object.assign({}, this.config), this.props.defaults)
      );
    }
  }

  build_treeData(types: TreeTaxonomy[], selectedNodes) {
    const childrens = [];
    for (const type in types) {
      // expand by default
      // types[type].expanded = true;

      // select previously selected items
      if (selectedNodes && Object.keys(selectedNodes).length > 0) {
        types[type].checked =
          selectedNodes.values.indexOf(types[type].value) > -1;
      }

      types[type].childrens = [];
      for (const child in types[type].children) {
        childrens.push(types[type].children[child].value);
        types[type].childrens.push(types[type].children[child].value);
      }
      const [itself, sub_childs] = this.build_treeData(
        types[type].children,
        selectedNodes
      );
      types[type].childrens.push(...sub_childs);
      types[type].children = itself;
    }
    return [types, childrens];
  }

  criteria_proportional(range_val: Array<number>, min: number, max: number) {
    return {
      min: (range_val[0] / 100) * (max - min) + min,
      max: (range_val[1] / 100) * (max - min) + min,
    };
  }

  range_change(criteriaName: RangeCriteriaNames, range_val: Array<number>) {
    const criteria = {
      [criteriaName]: this.criteria_proportional(
        range_val,
        this.config[criteriaName].min,
        this.config[criteriaName].max
      ),
    };
    this.setState({
      ...this.state,
      ...criteria,
    });
    const criteriaType =
      criteria[criteriaName].min === this.config[criteriaName].min &&
      criteria[criteriaName].max === this.config[criteriaName].max
        ? "disabled"
        : "range";
    this.props.handler({
      [criteriaName]: { ...criteria[criteriaName], type: criteriaType },
    });
  }

  alcohol_change(range_val: Array<number>) {
    this.range_change("alcohol", range_val);
  }

  temperature_change(range_val: Array<number>) {
    this.range_change("temperature", range_val);
  }

  density_change(range_val: Array<number>) {
    this.range_change("density", range_val);
  }

  attenuation_change(range_val: Array<number>) {
    this.range_change("attenuation", range_val);
  }

  quantity_change(event) {
    let val = event.target.value;
    this.props.quantity_change(val || false);
  }

  onDropdownChange(name: DropdownCriteriaNames, _, selectedNodes) {
    let nodes = [];

    selectedNodes.forEach((node) => {
      nodes.push(node.value);
      nodes.push(...node.childrens);
    });

    let criteria = {};
    criteria[name] = {
      values: nodes,
    };
    this.setState(criteria);
    criteria[name].type = "select";
    if (nodes.length === 0) {
      criteria[name].type = "disabled";
    }
    this.props.handler(criteria);
  }

  onTypeChange(currentNode, selectedNodes) {
    this.onDropdownChange("yeasts_types", currentNode, selectedNodes);
  }

  onSpeciesChange(currentNode, selectedNodes) {
    this.onDropdownChange("yeasts_species", currentNode, selectedNodes);
  }

  onFlocculationChange(currentNode, selectedNodes) {
    this.onDropdownChange("yeast_flocculation", currentNode, selectedNodes);
  }

  render() {
    return (
      <div className="yeasts-filter box">
        <div className="yeasts-filter-types">
          <DropdownContainer
            data={this.types}
            onChange={this.onTypeChange.bind(this)}
            placeholderText="Type de levure"
            noMatchesText="Aucun type correspondant"
            className="yeasts-type"
          />
          <DropdownContainer
            data={this.species}
            onChange={this.onSpeciesChange.bind(this)}
            placeholderText="Espèce de levure"
            noMatchesText="Aucune espèce correspondante"
            className="yeasts-species"
          />
        </div>
        <div className="yeasts-ranges">
          <YeastsFilterRange
            title={messages.alcohol_interval_title}
            config={this.config.alcohol}
            values={this.state.alcohol}
            onChange={this.alcohol_change.bind(this)}
          />
          <YeastsFilterRange
            title={messages.temperature_interval_title}
            config={this.config.temperature}
            values={this.state.temperature}
            onChange={this.temperature_change.bind(this)}
          />
          <YeastsFilterRange
            title={messages.attenuation_interval_title}
            config={this.config.attenuation}
            values={this.state.attenuation}
            onChange={this.attenuation_change.bind(this)}
          />
          <DropdownContainer
            data={this.flocculations}
            onChange={this.onFlocculationChange.bind(this)}
            className="flocculation"
            placeholderText="Floculation"
            noMatchesText="Aucun type de floculation correspondant"
          />
          <div className="quantity">
            <FormattedMessage {...messages.quantity_input_title} />
            <div>
              <input min="0" type="number" onChange={this.quantity_change} />
              <span>L</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

type RangeCriteriaNames = "alcohol" | "temperature" | "attenuation";
type DropdownCriteriaNames =
  | "yeasts_types"
  | "yeasts_species"
  | "yeast_flocculation";
interface YeastsFilterProps {
  defaults: object;
  types: Array<WpTaxonomy>;
  species: Array<WpTaxonomy>;
  flocculations: Array<WpTaxonomy>;
  handler: Function;
  quantity_change: Function;
}
type YeastsFilterRanges = {
  [key in RangeCriteriaNames]: Range;
};
type YeastsFilterDropdown = {
  [key in DropdownCriteriaNames]: object;
};
type YeastsFilterState = YeastsFilterRanges & YeastsFilterDropdown;

export default YeastsFilter;
