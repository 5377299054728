import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Map from "../components/clients/Map";
import ClientsList from "../components/clients/ClientsList";
import scrollToComponent from "react-scroll-to-component";

import "../sass/_map.scss";

class Clients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popupInfo: null,
    };
  }

  setMapPopup(client) {
    this.setState({ popupInfo: client });
    scrollToComponent(this.Map);
  }

  render() {
    const clients = this.props.data.clients.nodes;
    return (
      <div>
        <Map
          clients={clients}
          ref={(map) => {
            this.Map = map;
          }}
          popupInfo={this.state.popupInfo}
        />
        <ClientsList
          clients={clients}
          setMapPopup={this.setMapPopup.bind(this)}
        />
      </div>
    );
  }
}

Clients.propTypes = {
  clients: PropTypes.array,
};

export const query = graphql`
  query Clients {
    clients: allWpProducer {
      nodes {
        content
        meta: producteurs {
          url
          localisation {
            streetAddress
            latitude
            longitude
          }
        }
        title
        id
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        types: prodTypes {
          nodes {
            slug
          }
        }
      }
    }
  }
`;

Clients.title = "Clients";
export default Clients;
