var EllipticGradient = {
  // http://rectangleworld.com/blog/archives/169
  draw(ctx, x, y, radiusX, radiusY, rotation, opacity = 1) {
    /*
		The code below will fill a rectangle of width rectW and height rectH with an elliptical gradient.
		The gradient will be centered at (x, y),
		the radius in the x direction will be radiusX,
		the radius in the y direction will be radiusY.

		The particular chosen parameters x and y below create a gradient centered at the center of the rectangle.
		The choices for radii radiusX and radiusY create a gradient with proportions matching the proportions of the rectangle,
		with the final gradient color achieved at the corners of the rectangle.
		*/

    var scaleX;
    var scaleY;
    var invScaleX;
    var invScaleY;
    var grad;

    // var factor = 70;
    // we create a circular gradient, but after transforming it properly (by shrinking in either the x or y direction),
    // we will have an elliptical gradient.
    if (radiusX >= radiusY) {
      scaleX = 1;
      invScaleX = 1;
      scaleY = radiusY / radiusX;
      invScaleY = radiusX / radiusY;
      grad = ctx.createRadialGradient(0, 0, 0, 0, 0, radiusX);
    } else {
      scaleY = 1;
      invScaleY = 1;
      scaleX = radiusX / radiusY;
      invScaleX = radiusY / radiusX;
      grad = ctx.createRadialGradient(0, 0, 0, 0, 0, radiusY);
    }

    ctx.fillStyle = grad;

    // add desired colors
    grad.addColorStop(0, "rgba(0, 0, 0, 0)");
    grad.addColorStop(0.5, "rgba(0, 0, 0, " + opacity / 2 + ")");
    grad.addColorStop(0.8, "rgba(0, 0, 0, " + opacity + ")");
    grad.addColorStop(0.991, "rgba(0, 0, 0, " + opacity + ")");
    grad.addColorStop(0.9999, "rgba(0, 0, 0, 0)");
    grad.addColorStop(1, "rgba(0, 0, 0, 0)");

    var angle = Math.PI + rotation;
    var angle_sin = Math.sin(angle);
    var angle_cos = Math.cos(angle);
    // TODO recalculer la matrice
    ctx.setTransform(
      scaleX * angle_cos,
      scaleX * angle_sin,
      -scaleY * angle_sin,
      scaleY * angle_cos,
      x,
      y
    );

    // draw region to be gradient filled, making sure to inverse-scale any coordinates used for the drawing.
    ctx.beginPath();
    let height = 2 * radiusX * invScaleX;
    let width = 2 * radiusY * invScaleY;

    ctx.fillRect(-(1 / 2) * width, -(1 / 2) * height, width, height);
    ctx.setTransform(1, 0, 0, 1, 0, 0);
  },
};

export default EllipticGradient;
