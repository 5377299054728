import { graphql } from "gatsby";
import React from "react";
import Form from "../components/Form";

interface ContactProps {
  data: {
    contact: {
      title: string;
      content: string;
    };
  };
}
const Contact: React.FC<ContactProps> = ({ data }) => {
  return <Form data={data} />;
};
/* @ts-ignore */
Contact.title = "Contact";
export default Contact;
export const query = graphql`
  query Contact {
    contact: wpPage(slug: { eq: "contact" }) {
      id
      title
      content
    }
  }
`;
