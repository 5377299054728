import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import messages from "./messages";

import ExpandableList from "../../components/ExpandableList";
import Person from "./Person";

import "../../sass/_community.scss";

class Community extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      content: [],
    };
  }

  render() {
    const { community } = this.props;
    return [
      <div key="community-title" className="community-title-wrapper">
        <div className="box-wrapper community-title">
          <h2 className="box-title">
            <FormattedMessage {...messages.community_title} />
          </h2>
        </div>
      </div>,
      <ExpandableList key="community" className="person">
        {community.map((person) => {
          return <Person {...person} key={person.id} />;
        })}
      </ExpandableList>,
    ];
  }
}

Community.propTypes = {
  community: PropTypes.array,
};

export default Community;
