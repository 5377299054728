import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// import scrollToComponent from 'react-scroll-to-component';

class Person extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      // yeast_id: props.match.params.yeast_id
      isOpen: this.props.open === undefined ? false : this.props.open,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.isOpen) {
      this.setState(
        {
          isOpen: nextProps.open,
        } /*, function() {
				scrollToComponent(this.ref.current, {
					offset: -50,
					align: 'top',
					duration: 1000
				});
			} */
      );
    }
  }

  toggle() {
    const isOpen = !this.state.isOpen;
    this.props.on_toggle(this.props.id, isOpen);
  }

  render() {
    return (
      <div
        onClick={this.toggle.bind(this)}
        ref={this.ref}
        className={"single-person" + (this.state.isOpen ? " open" : "")}
      >
        <div className="single-person-wrapper">
          <main>
            <div className="thumbnail">
              <div className="img-wrapper">
                <GatsbyImage
                  image={getImage(
                    this.state.isOpen
                      ? this.props.featuredImage.node.localFile.full
                      : this.props.featuredImage.node.localFile.thumbnail
                  )}
                  alt={this.props.title}
                  className="image"
                />
              </div>
              {!this.state.isOpen && <h3>{this.props.title}</h3>}
            </div>

            {this.state.isOpen && (
              <div className="description">
                <h3>{this.props.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: this.props.content }}></p>
              </div>
            )}
          </main>
        </div>
      </div>
    );
  }
}

export default Person;
