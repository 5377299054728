import React from "react";
import PropTypes from "prop-types";
import ReactMapGL, { Marker, Popup } from "react-map-gl";

// added the following 6 lines.
import mapboxgl from "mapbox-gl";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
import workerClass from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
mapboxgl.workerClass = workerClass;

import { GatsbyImage } from "gatsby-plugin-image";

import { BeerPin } from "./MapPins";

var map_style = require("../../../map_style.json");

const bounds = [
  [5, 45.5],
  [11.30135, 48],
];

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.state = {
      viewport: {
        width: 400,
        height: 800,
      },
      popupInfo: props.popupInfo,
    };
  }

  updateSize() {
    if (this.container.current) {
      const width = parseInt(
        window
          .getComputedStyle(this.container.current)
          .getPropertyValue("width")
      );
      this.setState(function (prevState) {
        prevState.viewport.width = width;
        prevState.viewport.height = width * 0.61;

        return prevState;
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateSize.bind(this));
    this.updateSize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSize.bind(this));
  }

  componentDidUpdate(prevProps) {
    if (!this.props.popupInfo) {
      return;
    }
    if (prevProps.popupInfo === null && this.state.popupInfo === null) {
      this.setState({ popupInfo: this.props.popupInfo });
      return;
    }
    if (prevProps.popupInfo.id === this.props.popupInfo.id) {
      return;
    }
    if (
      this.state.popupInfo == null ||
      this.props.popupInfo.id !== this.state.popupInfo.id
    ) {
      this.setState({ popupInfo: this.props.popupInfo });
    }
  }

  renderPopup() {
    const { popupInfo } = this.state;

    return (
      popupInfo && (
        <Popup
          tipSize={5}
          offset={[0, -64]}
          anchor="bottom"
          longitude={parseFloat(popupInfo.meta.localisation.longitude, 10)}
          latitude={parseFloat(popupInfo.meta.localisation.latitude, 10)}
          onClose={() => this.setState({ popupInfo: false })}
          closeOnClick={false}
        >
          <div className="content">
            {popupInfo.featuredImage &&
              popupInfo.featuredImage.node &&
              popupInfo.featuredImage.node.localFile &&
              popupInfo.featuredImage.node.localFile.childImageSharp &&
              popupInfo.featuredImage.node.localFile.childImageSharp
                .gatsbyImageData && (
                <GatsbyImage
                  image={
                    popupInfo.featuredImage.node.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={popupInfo.title}
                  className="image"
                />
              )}
            <header>
              <h3>{popupInfo.title}</h3>
              {popupInfo.meta.url && (
                <a href={popupInfo.meta.url}>{popupInfo.meta.url}</a>
              )}
              <p className="address">
                {popupInfo.meta.localisation.streetAddress}
              </p>
            </header>
          </div>
          {/* <div dangerouslySetInnerHTML={{ __html: popupInfo.content }}></div> */}
        </Popup>
      )
    );
  }

  render() {
    const { clients } = this.props;
    return (
      <div className="box-wrapper">
        <div className="box">
          <div className="map-wrapper" ref={this.container}>
            <ReactMapGL
              style={{
                width: this.state.viewport.width,
                height: this.state.viewport.height,
              }}
              initialViewState={{
                latitude: 46.834215,
                longitude: 8.30135,
                bounds: bounds,
              }}
              maxBounds={bounds}
              {...this.state.viewport}
              mapStyle={map_style}
              mapboxAccessToken="pk.eyJ1IjoidGlib3J2YXVnaGFuIiwiYSI6ImNrenVqanJqNjE3bWMydW1vbWlwZ2o2bmIifQ.BIFjyGMMDjpU2dulbgaouw"
              //onViewportChange={(viewport) => this.setState({ viewport })}
              mapboxApiAccessToken="pk.eyJ1Ijoia29rb25nMiIsImEiOiJjamtoMjZvODUwZTFyM2twa3c1cmpyb3RoIn0.pMDDWf0q2GIzcsl4WhS87A"
              dragRotate={false}
              touchZoomRotate={false}
            >
              {clients
                .filter((p) => p.meta != null)
                .map((producer) => {
                  return (
                    <Marker
                      key={producer.id}
                      latitude={parseFloat(
                        producer.meta.localisation.latitude,
                        10
                      )}
                      longitude={parseFloat(
                        producer.meta.localisation.longitude,
                        10
                      )}
                    >
                      {(() => {
                        return (
                          <BeerPin
                            onClick={() =>
                              this.setState({ popupInfo: producer })
                            }
                          />
                        );
                      })()}
                    </Marker>
                  );
                })}
              {this.renderPopup()}
            </ReactMapGL>
          </div>
        </div>
      </div>
    );
  }
}

Map.propTypes = {
  popupInfo: PropTypes.object,
  "popupInfo.id": PropTypes.number,
  clients: PropTypes.array,
};

export default Map;
